<template>
	<div class="app-container">		
		<el-table :data="tableData" v-loading="loading" style="width: 100%;" class="mt-3 mb-5" ref="tableTest" border fit highlight-current-row>
            <template v-for="title in ajaxTitles">
				<el-table-column v-if="title.prop == 'img_url'" :prop="title.prop" :label="title.label" :key="title.prop" :min-width="title.width">
					<template slot="header">
						<p class="search-label">{{title.label}}</p>
					</template>
					
					<template slot-scope="scope" >
						<el-image :src="(scope.row.img_url != '') ? scope.row.img_url : defaultImage" class="img-thumbnail"></el-image>
					</template>
				</el-table-column>
				
				<el-table-column v-else :prop="title.prop" :label="title.label" :key="title.prop" :min-width="title.width">
					<template slot="header">
						<p class="search-label">{{title.label}}</p>
					</template>
				</el-table-column>
			</template>
			
			<el-table-column :label="$t('admin_general.table_action')" width="120">
				<template slot-scope="scope" >
					<el-button type="primary" :loading="buttonloading" @click="getEditRow(scope.row.id)" v-if="permissionList.includes(permission.edit)" icon="el-icon-setting">{{$t('button.config')}}</el-button>
				</template>
			</el-table-column>
        </el-table>

		<el-tooltip placement="top" :content="$t('general.back_to_top')">
			<backtotop :visibility-height="300" :back-position="50" transition-name="fade" />
		</el-tooltip>

		<b-modal id="editRow" :title="$t('menu.front_advertisement_edit')" hide-footer @hide="clearDataList()" no-enforce-focus>
			<el-form id="editForm" @submit.prevent="submit" enctype="multipart/form-data">
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('general.date_range')}}</template>
					<el-date-picker ref="editDate" v-model="dataList.date_range" type="daterange" align="right" unlink-panels :range-separator="$t('general.to')" :start-placeholder="$t('general.date_start')" :end-placeholder="$t('general.date_end')" :picker-options="pickerOptions" class="filter-item" value-format="yyyy-MM-dd" :disabled="loading"></el-date-picker>
				</b-form-group>
				
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_url')}}</template>
					<b-form-input v-model="dataList.url"></b-form-input>
				</b-form-group>
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_sort')}}</template>
					<b-form-input v-model="dataList.sort"></b-form-input>
				</b-form-group>

				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_status')}}</template>
					<el-switch v-model="dataList.status" active-value="1" inactive-value="0"> </el-switch>
				</b-form-group>
						
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_image')}}</template>
					<el-popover placement="right" width="130" trigger="click" class="img-thumbnail" v-model="popover">
						<el-button type="primary" icon="el-icon-edit" @click="openImage();"></el-button>
						<el-button type="danger" icon="el-icon-delete" @click="initialImage();"></el-button>
						
						<el-image style="width: 150px; height: 150px" fit="fill" :src="dataList.img_url" slot="reference"></el-image>
					</el-popover>
				</b-form-group>
						
				<b-form-group label-cols="12" label-cols-lg="4">
					<template slot="label">{{$t('admin_general.table_security')}}</template>
					<b-form-input v-model="dataList.security" type="password" :placeholder="$t('msg.msg_security_password')"></b-form-input>
				</b-form-group>
			</el-form>

			<el-footer align="center" class="element-footer">
				<el-button size="medium" @click="$bvModal.hide('editRow')">{{$t('button.cancel')}}</el-button>
				<el-button size="medium" type="primary" @click="editRow()" :loading="buttonloading">{{$t('button.submit')}}</el-button>
			</el-footer>
        </b-modal>
		
		<imagemanager ref="imagemanager" :isShowDialog="openImageDialog" @dialogData="closeImageDialog" @getData="getImageDialog"/>
	</div>

</template>

<script>
import {getLocalStorage} from '@/system/store/localstorage';
import {postMethod} from '@/system/model/post/post';
import {getDAES, getEAES} from '@/system/library/security';
import backtotop from '@/components/backtotop';
import imagemanager from '@/views/image';

let searchForm = {
	id: '',
	img_url:'',
	start_at:'',
	end_at:'',
	url:'',
	sort:'',
	status:'',
}
			
export default{
	components: {backtotop, imagemanager },
	inject:['preloader'],
	data(){
		return {
			loading: true,
			buttonloading: false,
			openImageDialog: false,
			tableData: [],
			total: 0,
			errors: [],
			submitForm: {
				id:'',
			},
			postData: {
				data: '',
				language: '',
			},
			searchData: Object.assign({}, searchForm),
			listQuery: {
				page: 1,
				limit: 10
			},
			ajaxTitles:[{
                prop: "img_url",
                label: this.$t('admin_general.table_image'),
                width:'100'
			},{
                prop: "update_at",
                label: this.$t('admin_general.table_updated_at'),
                width:'100'
            },{
                prop: "start_at",
                label: this.$t('admin_general.table_date_start'),
                width:'110'
            },{
                prop: "end_at",
                label: this.$t('admin_general.table_date_end'),
                width:'110'
            },{
                prop: "name",
                label: this.$t('admin_general.table_name'),
                width:'100'
			},{
                prop: "price",
                label: this.$t('admin_general.table_price'),
                width:'100'
			},{
                prop: "url",
                label: this.$t('admin_general.table_url'),
                width:'100'
			},{
                prop: "sort",
                label: this.$t('admin_general.table_sort'),
                width:'60'
			},{
                prop: "status",
                label: this.$t('admin_general.table_status'),
                width:'60'
			}],
			defaultImg: '',
			dataList:{
				img_url:'',
				date_range:[],
				url:'',
                sort:'100',
                status:'0',
                security:''
			},
			permission:{
                index:'Z35IO5',
                ajaxTable:'95QG3P',
                edit:'OS6S2H',
                DBedit:'JP1N51'
            },
            permissionList:[],
            languageList:[],
			popover: false,
			pickerOptions: {
				shortcuts: [{
					text: this.$t('general.recent_week'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				},{
					text: this.$t('general.recent_one_month'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				},{
					text: this.$t('general.recent_three_month'),
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
            },
		}
	}, methods: {
		getInitial(){
			if(this.permissionList.includes(this.permission.index)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var self = this;
				var result = postMethod('front/advertisement',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.defaultImage = data.defaultImg;
						self.dataList.img_url = data.defaultImg;
						self.initial();
					}
				});
			}
        },
		initial(){
			if(this.permissionList.includes(this.permission.ajaxTable)){
				this.loading = true;
				this.buttonloading = true;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.searchData));
				var self = this;
				var result = postMethod('front/advertisement/ajaxTable',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.tableData = data.datatable.data;
						self.total = parseInt(data.datatable.total_number);
						self.listQuery.page = parseInt(data.datatable.current_pagination);
						self.listQuery.limit = parseInt(data.datatable.limit);
						self.buttonloading = false;
						self.loading = false;
					}
				});
			}
		},clearDataList(){
			this.dataList.img_url = this.defaultImage;
			this.dataList.url = '';
			this.dataList.sort = '100';
			this.dataList.status = '0';
			this.dataList.security = '';
		},getEditRow(id){
			if(this.permissionList.includes(this.permission.edit)){
				this.buttonloading = true;
				this.submitForm.id = id;
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var self = this;
				var result = postMethod('front/advertisement/edit',this.postData);
				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.dataList = data.thisDetail;
						
						if(self.dataList.img_url == ''){
							self.dataList.img_url = self.defaultImage;
						}
						
						self.$bvModal.show('editRow');
					}
					self.buttonloading = false;
				});
			}
		},editRow(){
			if(this.permissionList.includes(this.permission.DBedit)){
				this.buttonloading = true;
				this.preloader(true);
				this.postData.data = '';
				this.postData.data = getEAES(JSON.stringify(this.dataList));
				var text = '';
				var self = this;
				var result = postMethod('front/advertisement/DBedit',this.postData);

				result.then(function(value){
					var data = JSON.parse(getDAES(value.data));

					if(value.valid){
						self.$message({
							message: data.returnMsg,
							type: 'success'
						});
						
						self.$bvModal.hide('editRow');
						self.initial();
					}else{					
						data.returnMsg.forEach(function (value){
							text+= value+"<br/>";
						});
						
						self.$method.popupErrorMessage(self,text);
					}
					
					self.buttonloading = false;
					self.preloader(false);
				});
			}
		},deleteRow(id){
			if(this.permissionList.includes(this.permission.DBdelete)){
				this.buttonloading = true;
				var text = '';
				var self = this;
				
				this.$prompt(this.$t('msg.msg_delete'), this.$t('msg.prompt'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputPlaceholder: this.$t('msg.msg_security_password'),
					inputType: 'password'
				}).then(({ value }) => {
					this.submitForm.id = id;
					this.submitForm.security = value;
					this.postData.data = '';
					this.postData.data = getEAES(JSON.stringify(this.submitForm));
					var result = postMethod('front/carousel/DBdelete',this.postData);
					result.then(function(value){
					var data = JSON.parse(getDAES(value.data));
					if(value.valid){
						self.$message({
						type: 'success',
						message: data.returnMsg
						});
						
						self.initial();
					}else{
						self.errors = data.returnMsg;
						
						self.errors.forEach(function (value){
						text+= value+"<br/>";
						});
						
						self.$method.popupErrorMessage(self, text);
					}
					
					});
					
					this.buttonloading = false;

				}).catch(() => {
					this.buttonloading = false;          
				});
			}
		},initialImage(){
			this.dataList.img_url = this.defaultImg;
			this.popover = false;
		},closeImageDialog(data) {
			this.openImageDialog = data;
		},openImage(){
			this.openImageDialog = true;
			this.$refs.imagemanager.onOpen(0); 
			this.popover = false;
		},getImageDialog(data){
			this.dataList.img_url = data.path;
			this.openImageDialog = false;
		},saveData(){
			searchForm = this.searchData;
		}
	}, created: function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.postData.language = currentLang;
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
        this.languageList = JSON.parse(getLocalStorage('languageList'));
        this.getInitial();
	}
}
</script>